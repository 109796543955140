.ant-radio,
.ant-radio-group,
.ant-radio-wrapper {
  font-size: $font-size-base;
  color: $body-color;
}

.ant-radio {
  &:hover {
    .ant-radio-inner {
      border-color: $radio-theme-color;
    }
  }
}

.ant-radio-wrapper {
  line-height: $line-height-base;
  margin-right: $radio-gutter;

  &:hover {
    .ant-radio {
      .ant-radio-inner {
        border-color: $radio-theme-color;
      }
    }
  }
  .ant-radio-inner {
    width: $radio-width-height;
    height: $radio-width-height;
    border-width: 2px;
    border-style: solid;
    border-color: $input-border-color;
    background-color: transparent;
  }
  &:not(.ant-radio-default) .ant-radio-inner:after {
    height: $radio-dot-size;
    width: $radio-dot-size;
    left: $radio-position-left;
    top: $radio-position-top;
    background-color: $radio-theme-color;
  }
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: $radio-theme-color;
  }
}

.ant-radio-focused {
  .ant-radio-inner {
    border-color: $radio-theme-color;
  }
}

.ant-radio-disabled {
  .ant-radio-inner {
    border-color: $border-color !important;
    background-color: $radio-disable-bg;

    &:after {
      background-color: rgba($body-color, 0.5);
    }
  }
}
.ant-radio-wrapper-disabled {
  .ant-radio-disabled {
    .ant-radio-inner {
      background-color: #e8ebf1;
      &:after {
        background-color: #e8ebf1;
      }
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: #ffffff;
      &:after {
        background-color: #e8ebf1;
      }
    }
  }
}

.ant-radio-group {
  .ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
      z-index: 1;
      color: $radio-theme-color;
      background: $white;
      border-color: $radio-theme-color;
    }
  }
}

.ant-radio-button-wrapper {
  height: $btn-size;
  margin: 0;
  padding: 0 $btn-padding-x;
  color: $body-color;
  line-height: $btn-size - 0.125rem;
  background: $white;
  border: 1px solid $border-color;
  border-left: 0;

  &:first-child {
    border-left: 1px solid $border-color;
    border-radius: $border-radius 0 0 $border-radius;
  }

  &:last-child {
    border-radius: 0 $border-radius $border-radius 0;
  }

  &:not(:first-child) {
    &:before {
      background-color: $border-color;
    }
  }

  &:hover {
    color: $radio-theme-color;
  }
}

.ant-radio-group-solid {
  .ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
      color: $white;
      background: $radio-theme-color;
      border-color: $radio-theme-color;

      &:hover {
        color: $white;
        background: lighten($radio-theme-color, 5%);
        border-color: lighten($radio-theme-color, 5%);
      }

      &:active {
        color: $white;
        background: darken($radio-theme-color, 10%);
        border-color: darken($radio-theme-color, 10%);
      }
    }
  }
}

.ant-radio-group-large {
  .ant-radio-button-wrapper {
    height: $btn-size-lg;
    font-size: $font-size-md;
    line-height: $btn-size-lg - 0.125rem;
    padding: 0 $btn-padding-x-lg;
  }
}

.ant-radio-group-small {
  .ant-radio-button-wrapper {
    height: $btn-size-sm;
    font-size: $font-size-sm;
    line-height: $btn-size-sm - 0.125rem;
    padding: 0 $btn-padding-x-sm;
  }
}

.ant-radio-button-wrapper-checked {
  &:not(.ant-radio-button-wrapper-disabled) {
    &:before {
      background-color: $radio-theme-color;
    }

    &:hover {
      &:before {
        background-color: $radio-theme-color;
      }
    }
  }

  &:not([class*=' ant-radio-button-wrapper-disabled']) {
    &.ant-radio-button-wrapper:first-child {
      border-right-color: $radio-theme-color;
    }
  }
}

.ant-radio-button-wrapper-disabled {
  color: rgba($body-color, 0.25);
  background-color: $btn-disabled-color;
  border-color: $border-color;

  &:first-child,
  &:hover {
    color: rgba($body-color, 0.25);
    background-color: $btn-disabled-color;
    border-color: $border-color;
  }

  &.ant-radio-button-wrapper-checked {
    color: $white;
    background-color: darken($btn-disabled-color, 10%);
    border-color: $border-color;
  }
}

.color-selector {
  .ant-radio {
    &:after {
      width: 25px;
      height: 25px;
    }
  }

  .ant-radio-inner {
    border-color: transparent;
    border-radius: $border-radius-sm;

    &:after {
      height: 10px;
      width: 6px;
      left: 5px;
      top: 2px;
      background-color: transparent;
      border-radius: 0px;
      border: 2px solid $white;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
    }
  }

  .ant-radio-wrapper {
    line-height: 0;
    border-radius: $border-radius-sm;

    &:hover {
      .ant-radio {
        .ant-radio-inner {
          border-color: transparent;
        }
      }
    }

    &.bg-white {
      .ant-radio-inner {
        border-color: $border-color;

        &:after {
          border-color: $gray;
        }
      }

      &:hover {
        .ant-radio {
          .ant-radio-inner {
            border-color: $border-color;
          }
        }
      }
    }
  }

  span.ant-radio + * {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ant-radio-checked {
    &:after {
      animation: none;
      transform: rotate(45deg);
      border-color: transparent;
    }
  }
}
