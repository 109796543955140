.ant-tooltip {
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $body-color;
  z-index: $zindex-tooltip;
}

.ant-tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-spacer-y $tooltip-spacer-x;
  color: $white;
  text-align: left;
  text-decoration: none;
  background-color: $tooltip-bg;
  border-radius: $border-radius;
  box-shadow: 0 2px 8px rgba($gray-dark, 0.15);
  min-height: auto;
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 8px;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftBottom,
.ant-tooltip-placement-leftTop {
  padding-right: $tooltip-placement-gutter;

  .ant-tooltip-arrow {
    right: $tooltip-arrow-position;
    border-width: 5px 0 5px 5px;
    border-left-color: $tooltip-bg;
    width: 9px;
    height: 21px;
  }
}
.ant-tooltip-arrow-content,
.ant-tooltip-arrow-content::before {
  background: linear-gradient(to right bottom, $tooltip-bg, $tooltip-bg);
  border-radius: 0;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: $tooltip-placement-gutter;

  .ant-tooltip-arrow {
    top: $tooltip-arrow-position;
    border-width: 0 5px 5px;
    border-bottom-color: $tooltip-bg;
  }
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightBottom,
.ant-tooltip-placement-rightTop {
  padding-left: $tooltip-placement-gutter;

  .ant-tooltip-arrow {
    left: $tooltip-arrow-position;
    border-width: 5px 5px 5px 0;
    border-right-color: $tooltip-bg;
  }
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: $tooltip-placement-gutter;

  .ant-tooltip-arrow {
    bottom: $tooltip-arrow-position;
    border-width: 5px 5px 0;
    border-top-color: $tooltip-bg;
  }
}

.ant-tooltip-arrow::before {
  background-color: $tooltip-bg;
}
