.device-details-modal {
  height: 80vh;
  margin-top: 8vh;
  min-height: 88vh;
  width: 80vw !important;
  .ant-modal-content {
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .ant-modal-close {
      color: #253858;
      font-size: 14px;
      .ant-modal-close-x {
        font-size: 14px;
      }
    }
    .ant-modal-header .ant-modal-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #172b4d;
    }
    .ant-btn {
      background-color: #0052cc !important;
      padding: 12px 64px;
      height: 48px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border-radius: 4px;
      span {
        color: #ffffff;
      }
      &:disabled,
      &:disabled span {
        color: #c1c7d0 !important;
        background-color: #0073e6 !important;
      }
    }
    .ant-modal-footer {
      background: white;
    }
    .ant-modal-body {
      flex: 1;
      background-color: #f5f5f5;
      // overflow: auto;
      padding: 10px 15px;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
      text-align: center;
    }
    .ant-tabs-tab {
      padding: 0 10 !important;
      font-weight: 500;
      font-size: 16px;
      width: 128px;
      line-height: 24px !important;
      background-color: #ffffff;
      border-radius: 0;
      border: 1px solid #ebecf0;
      margin: 0 !important;
      color: #5e6c84 !important;
    }
    .ant-tabs-tab:first-child {
      border-radius: 8px 0px 0px 0px;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #172b4d !important;
    }
    .inner-card {
      .ant-card-body {
        background-color: #ffffff;
      }
    }
    .ant-tabs-content {
      overflow: auto;
      height: 58vh;
      font-size: 16px;
    }
    .tabpane-content {
      font-size: 16px;
      letter-spacing: 0.2px;
      // font-weight: normal !important;
      line-height: 24px;
    }
    .content-title {
      color: #172b4d;
      margin-bottom: 5px;
    }
    .content-info {
      color: #5e6c84;
    }
    .info-input {
      background: #fafbfc;
      border: 1px solid #dfe1e6;
      box-sizing: border-box;
      border-radius: 4px;
      width: 314px;
      margin-bottom: 0px;
      margin-top: 5px;
    }
    .ant-select-selector {
      // border:0;
      font-size: 14px !important;
      line-height: 20px;
      color: #172b4d;
    }
  }
}

.mycollection-details-modal {
  width: 50vw !important;
  .ant-modal-content {
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .ant-modal-close {
      color: #253858;
      font-size: 14px;
      .ant-modal-close-x {
        font-size: 14px;
      }
    }
    .ant-modal-header .ant-modal-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #172b4d;
    }
    .ant-btn {
      background-color: #0073e6;
      padding: 12px 50px;
      height: 48px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border-radius: 4px;
      span {
        // color:#FFFFFF;
      }
    }
    .ant-modal-footer {
      background: white;
    }
    .ant-modal-body {
      flex: 1;
      background-color: #f5f5f5;
      // overflow: auto;
      padding: 10px 15px;
    }
    .inner-card {
      .ant-card-body {
        background-color: #ffffff;
      }
    }
    .content-title {
      color: #172b4d;
      margin-bottom: 5px;
    }
    .content-info {
      color: #5e6c84;
    }
    .info-input {
      background: #fafbfc;
      border: 1px solid #dfe1e6;
      box-sizing: border-box;
      border-radius: 4px;
      width: 314px;
      margin-bottom: 0px;
      margin-top: 5px;
    }
    .ant-select-selector {
      // border:0;
      font-size: 14px !important;
      line-height: 20px;
      color: #172b4d;
    }
  }
}

.authorize-modal {
  width: max-content !important;
  .ant-modal-content {
    width: 'max-content' !important;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .ant-modal-close {
      color: #253858;
      font-size: 14px;
      .ant-modal-close-x {
        font-size: 14px;
      }
    }
    .ant-modal-header .ant-modal-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #172b4d;
    }
    .ant-btn {
      background-color: #0052cc;
      padding: 12px 50px;
      height: 48px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border-radius: 4px;
    }
    .ant-modal-body {
      flex: 1;
      padding: 10px 15px;
    }
  }
}
