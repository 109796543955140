.btn-bg-color, .btn-new-user, .btn-new-user:hover, .ant-pagination-item-active, .ant-btn-primary, .ant-btn-primary:active {
    background-color: #0073E6 !important;
    &:has(button:disabled){
        background-color: inherit !important;
    }
} 
.ant-btn-background-ghost {
    background-color: transparent !important;
    &:has(button:disabled){
        background-color: inherit !important;
    }
} 
.del-btn, .btn-new-user, .empty-text, .user-card h4{
    font-weight: 500;
}
.btn-new-user {
    font-size:0.875rem;
}
.del-btn {
    color:#0052cc;
}
.empty-text {
    color : #172B4D;
    font-size: 14;
}

.dark-primary {
    color:#172B4D
}

.marning-right-8 {
    margin-right :8px;
} 
.user-card {
    margin-bottom:0;
}

.cancel-btn{
    background: #F4F5F7;
    border-radius: 4px;
}

.user-card > .ant-card-head, .user-card > .ant-card-body{
    background-color: #F3F6FA;
    border : none;
}

.user-form-input{
    background: #FAFBFC;
    border: 1px solid #DFE1E6;
    box-sizing: border-box;
    border-radius: 4px !important;
}

.user-card .initialSetupCard{
    min-height: calc(100vh - 250px);
}
.user-card h4, .user-card p {
    margin-bottom: 0;
}
.user-card h4{
    color:#172B4D;
    font-size:24px;
}

.user-card p, .user-card p a {
    font-weight: normal;
    font-size: 0.8rem;
}

.user-card .space-align-between{
    display: flex;
    justify-content: space-between;
}

.user-card .edit-icon{
    width: 12px;
    height: 16px;
    filter: opacity(65%);
}

.user-card .edit-icon:hover{
    cursor: pointer;
}
.user-card .ant-table-wrapper{
    border:1px solid #f0f0f0;
    border-radius: 10px;
}
.user-card .search-btn, .user-card .ant-input{
    background-color: #F5F5F5;
    box-sizing: border-box;
    border: 1px solid #DFE1E6;
    border-radius: 4px;
}
.user-card .ant-input-affix-wrapper{
    padding:5px;
    background-color: #F5F5F5;
}
.ant-checkbox-inner {
    width: 16px;
    height: 16px;
} 
.ant-checkbox-inner::after {
    left: 25%;
    top:50%;
}
.table-striped-rows tr:nth-child(2n) td {
    background-color: #FAFBFC;
}
.table-striped-rows thead {
    background-color: #FFFFFF;
}

.ant-table-thead > tr > th {
    background: #FFFFFF;
    font-size: 12px;
    color: #5E6C84;
    padding-top: 0.527vw;
    padding-bottom: 0.527vw;
}

.ant-table {
    color: #172B4D;
    font-size: 14px;
    background: #fff;
    font-weight: 400;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
}
.headerMargin{
    float: right;
}
.img-size {
    height: 0.5em;
    width: 0.5em;
}