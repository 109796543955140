.nav-profile {
  width: 8.5rem;

  .nav-profile-header {
    padding: 0.7rem 1rem;
    border-bottom: 1px solid $border-color;
  }

  .nav-profile-thumb {
    height: 40px;
    width: 40px;
    background: #ffab00;
    border-radius: 27px;
    margin-right: 10px;
    padding: 5px;
  }

  .nav-profile-name {
    margin: 0;
    color: #172b4d;
    font-weight: 500;
  }

  .nav-profile-mail {
    letter-spacing: 0.4px;
    font-size: 12px;
    color: #5e6c84;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: inherit;
  }

  .nav-profile-body {
    padding: 0.625rem 0;

    .font-weight-normal {
      margin-left: 0;
    }

    .ant-menu-vertical > .ant-menu-item,
    .ant-menu-vertical a {
      height: 20px;
      line-height: 20px;
      color: #172b4d !important;
      font-size: 14px;
      letter-spacing: 0.2px;
      margin-bottom: 12px;
    }
  }
}

.nav-dropdown {
  width: 14.5rem;
}
.nav-profile-dropdown-header {
  padding: 0.7rem 1rem;
  border-bottom: 1px solid $border-color;
}
