/* Override disabled and checked checkbox styles */
.ant-checkbox-wrapper .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff !important; /* Ant Design primary blue color */
  border-color: #1890ff  !important;
  opacity: .8; /* Ensure full opacity */
}

/* Change the checkmark color for disabled and checked checkboxes */
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white; /* Ensure checkmark stays white even when disabled */
}

/* Ensure unchecked disabled checkboxes remain gray */
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}