.ellipsis-dropdown {
  cursor: pointer;
  font-size: $font-size-lg;
  transform: rotate(90deg);
  display: inline-block;
  line-height: 1;
  padding: 0.3125rem;
  border-radius: $border-radius-circle;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: rgba($black, 0.05);
  }
}
