.custom-drawer {
  // letter-spacing: 0.2px;
  .ant-drawer-header-title {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .ant-drawer-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #172b4d;
    flex: none;
  }
  .close-icon {
    height: 14px;
    width: 14px;
    color: #253858;
    :hover {
      color: #455878;
    }
  }
  .ant-drawer-close {
    margin-right: 0;
  }
  .neutral-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5e6c84;
    margin-left: 26px;
  }
  .search-type-radio {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #172b4d;
  }
  .select-dropdown {
    background: #fafbfc;
    border-radius: 4px;
    // width:314px;
    // height: 44px;
    margin-left: 28px;
    font-weight: normal;
  }
  .auto-complete-search {
    margin-left: 28px;
  }
}
