.nav-notification {
  width: 19.5rem;

  .nav-notification-header {
    padding: 0.875rem;
    border-bottom: 1px solid $border-color;
  }

  .nav-notification-body {
    max-height: 21.875rem;
    overflow-y: auto;
  }

  .nav-notification-footer {
    padding: 0.65rem;
    text-align: center;
    border-top: 1px solid $border-color;
  }

  .empty-notification {
    padding: 4rem 0;
    text-align: center;
  }
}
