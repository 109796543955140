$breakpoint-xl: 1381px;
$breakpoint-lg: 1215px;
$breakpoint-md: 1012px;

.no-record-data {
  .ant-card-body {
    height: calc(100% - 50px);
    padding-bottom: 0;
  }
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .check-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        background: #00864f;
        border-radius: 50%;
        .anticon-check {
          color: white;
          font-size: 45px;
        }
      }
    }
  }
}
.record-list-table {
  border: none !important;
  font-size: 14px;
  .ant-table {
    overflow-x: unset;
  }
  .ant-table-tbody > tr > td {
    border: none;
    padding: 0;
    padding-bottom: 14px;
  }
  .ant-table-tbody > tr {
    line-height: 24px;
    padding-bottom: 14px;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset; //Change the existing color to `unset`
  }
  .version {
    color: $text-dark-secondary;
    // margin-left:5px;
  }
}

.chart-body {
  display: block;
  position: relative;
  canvas.chartjs-render-monitor {
    height: 250px !important;
    width: 125px !important;
    display: block;
    // margin: auto;
    text-align: center;
    color: black;
    margin-bottom: 0px;
    z-index: none;
    float: right;
  }
  .chart-data-list {
    padding-left: 0;
    font-size: 18px;
    line-height: 24px;
    list-style-type: none;
    width: max-content;
    max-width: 70%;
    li {
      padding-bottom: 10px;
    }
    .ant-badge-status-dot {
      width: 12px;
      height: 12px;
      border-radius: $border-radius-circle;
      // top: -3px;
    }
    .chart-data-list-lable {
      margin-left: 4px;
    }
    .chart-data-list-value {
      margin-left: 2px;
      // padding-left: 10px;
    }
    .chart-data-total {
      color: #646a78;
    }
    .chart-list-dot-style {
      width: 12px;
      height: 12px;
    }
  }
  .chart-data-chart {
    position: absolute;
    top: -60px;
    right: 0px;
    height: 160px;
    width: 270px;
  }
}

.data-cards-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .data-col:first-child,
  .data-col:nth-child(4) {
    padding: 5px;
    padding-left: 0px;
  }
  .data-col:nth-child(3),
  .data-col:nth-child(6) {
    padding: 5px;
    padding-right: 0px;
  }
  .data-col:nth-child(2),
  .data-col:nth-child(5) {
    padding: 5px;
  }
}

.video-blk iframe {
  border-radius: 8px;
}

.video-blk-title {
  font-size: 14px;
}

.techDocs-link {
  color: #0073e6 !important;
  font-size: 16px;
  font-weight: 500;
  padding-right: 10px;
  .anticon-link {
    padding-right: 10px;
  }
}

.data_card {
  height: 100%;
}
.data-card2 {
  height: 100%;
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    div {
      flex: 1;
    }
  }
}
@media screen and (max-width: 759px) {
  .data-col {
    width: 100%;
  }
}

@media screen and (min-width: 759px) {
  .data-col {
    width: 50%;
    .ant-card-body {
      padding: 10px;
    }
    .ant-card-head {
      padding-left: 10px;
    }
    .chart-body canvas.chartjs-render-monitor {
      width: 110px !important;
      height: 220px !important;
    }
    .chart-body .chart-data-chart {
      right: -5px;
    }
    canvas {
      overflow: visible !important;
      overflow-clip-margin: margin-box;
    }
  }
}

@media screen and (min-width: 868px) {
  .data-col {
    .chart-body canvas.chartjs-render-monitor {
      width: 125px !important;
      height: 250px !important;
    }
    .chart-body .chart-data-chart {
      top: -65px;
      right: 0px;
    }
  }
}
@media screen and (min-width: 1012px) {
  .data-col {
    .chart-body canvas.chartjs-render-monitor {
      width: 110px !important;
      height: 220px !important;
    }
    .chart-body .chart-data-chart {
      top: -65px;
      right: -5px;
    }
  }
}

@media screen and (min-width: 1215px) {
  .data-col {
    .chart-body canvas.chartjs-render-monitor {
      width: 135px !important;
      height: 270px !important;
    }
    .chart-body .chart-data-chart {
      top: -65px;
      right: 0px;
    }
    .data_card1 {
      padding-bottom: 25px !important;
    }
  }
}
@media screen and (min-width: 1381px) {
  .data-col {
    width: calc(100% / 3);
    .chart-body canvas.chartjs-render-monitor {
      width: 110px !important;
      height: 220px !important;
    }
    .data_card1 {
      padding-bottom: 4px !important;
    }
  }
}
@media screen and (min-width: 1519px) {
  .data-col {
    .chart-body canvas.chartjs-render-monitor {
      width: 130px !important;
      height: 260px !important;
    }
  }
}
